"use client";
import { useState } from "react";
import { icons } from "./SiteIcons";
import Image from "next/image";

type FAQElement = {
  question: string;
  answer: string | JSX.Element;
};
type FAQProps = {
  title: string;
  deploymentName?: string;
  FAQElements: FAQElement[];
};

export function FAQ(props: FAQProps) {
  const { title, FAQElements, deploymentName } = props;

  const titleFont = deploymentName === "k33" ? "font-inter" : "font-serif";

  return (
    <div
      id="faq-main-container"
      className="flex w-full flex-col gap-4 text-text"
    >
      <h2
        id="title"
        className={"text-faq-title text-[3rem] font-medium " + titleFont}
      >
        {title}
      </h2>
      <div id="faq-container" className="flex w-full flex-col">
        {FAQElements.map((FAQElement) => (
          <Question
            key={FAQElements.indexOf(FAQElement)}
            question={FAQElement.question}
            answer={FAQElement.answer}
            deploymentName={deploymentName}
          />
        ))}
      </div>
    </div>
  );
}

type QuestionProps = {
  question: string;
  deploymentName?: string;
  answer: string | JSX.Element;
};

function Question(props: QuestionProps) {
  const [open, setOpen] = useState(false);

  const { deploymentName } = props;

  const questionIcon =
    deploymentName === "k33" ? icons.triangle_grey : icons.triangle;

  return (
    <div
      className={
        "flex flex-col gap-2 " + (deploymentName === "k33" ? "border-t" : "")
      }
    >
      <div
        className="flex cursor-pointer flex-row gap-4 bg-faq py-4 pl-2"
        onClick={() => setOpen(!open)}
      >
        <Image
          className={open ? "rotate-90" : "rotate-0"} 
          alt={questionIcon.alt}
          width={questionIcon.width}
          height={questionIcon.height}
          src={questionIcon.path}
        />
        <div className="text-xl font-medium">{props.question} </div>
      </div>
      {open ? <div className="mb-4 text-xl px-12">{props.answer} </div> : ""}
    </div>
  );
}
