"use client";

import { BigFloat } from "shared/build/src/utils/BigFloat";
import { useState } from "react";
import { LoanDetailsProps } from "./LoanDetails";
import { getYearlyInterestRate } from "src/loan/LoanFunctions";
import {
  SupportedCollateralId,
  SupportedLoanCurrencyId,
} from "shared/build/src/SupportedCurrencies";
import {
  formatToDisplayString,
  formatToDisplayStringWithCurrency,
} from "src/formatting/functions";

export function useLoanDetails(args: {
  secondlyRate: BigFloat;
  loanCurrency: SupportedLoanCurrencyId;
  collateralCurrency: SupportedCollateralId | "BTC";
  loanAmountPlusFee: BigFloat;
  noDai: boolean;
  loanAmountInDai: BigFloat;
  loanAmountInDaiPlusFee: BigFloat;
  daiToNokRate: BigFloat;
  daiToUsdRate?: BigFloat;
  collateralToLoanRate: BigFloat;
  collateralBigFloat: BigFloat;
  liquidationPrice: BigFloat;
  loanFee?: BigFloat;
  maxLtv: BigFloat;
  liqLtv: BigFloat;
  ltv: BigFloat;
}) {
  const {
    secondlyRate,
    loanAmountPlusFee,
    loanCurrency,
    collateralCurrency,
    noDai,
    loanAmountInDaiPlusFee,
    loanAmountInDai,
    daiToNokRate,
    daiToUsdRate,
    collateralToLoanRate,
    collateralBigFloat,
    liquidationPrice,
    loanFee,
    ltv,
    maxLtv,
    liqLtv,
  } = args;

  const [detailsHidden, setDetailsHidden] = useState(true);

  const toggleDetails = () => setDetailsHidden(!detailsHidden);

  const interest = getYearlyInterestRate(secondlyRate);

  const loanDetailsProps: LoanDetailsProps = {
    interest: formatToDisplayString(interest),
    loanAmount: formatToDisplayStringWithCurrency(
      loanCurrency,
      loanAmountPlusFee
    ),
    loanAmountInDai:
      noDai || loanCurrency === "DAI"
        ? ""
        : formatToDisplayString(loanAmountInDaiPlusFee) + " $DAI",
    loanValueInNok:
      loanCurrency !== "NOK"
        ? formatToDisplayStringWithCurrency(
            "NOK",
            loanAmountInDai.mul(daiToNokRate)
          )
        : undefined,
    daiToUsdRate:
      daiToUsdRate && formatToDisplayStringWithCurrency("USD", daiToUsdRate),
    collateral: formatToDisplayString(collateralBigFloat),
    collateralCurrency: collateralCurrency,
    collateralInLoanCurrency: formatToDisplayStringWithCurrency(
      loanCurrency,
      collateralBigFloat.mul(collateralToLoanRate)
    ),
    collateralPrice: formatToDisplayStringWithCurrency(
      loanCurrency,
      collateralToLoanRate
    ),
    collateralLiqPrice: formatToDisplayStringWithCurrency(
      loanCurrency,
      liquidationPrice
    ),
    loanFee: loanFee
      ? formatToDisplayString(loanFee.mul(BigFloat.fromNumber(100)))
      : loanFee,
    currentLtv: formatToDisplayString(ltv.mul(BigFloat.fromNumber(100))),
    maxLtv: formatToDisplayString(maxLtv.mul(BigFloat.fromNumber(100))),
    liqLtv: formatToDisplayString(liqLtv.mul(BigFloat.fromNumber(100))),
  };

  return {
    detailsHidden,
    toggleDetails,
    loanDetailsProps,
  };
}
