"use client";
import { AssetId } from "shared";
import { ChangeCurrencyButton } from "./ChangeCurrencyButton";

type CalculatorInputFieldProps = {
  setSelectedCurrency: (currency: AssetId) => void;
  currencies?: readonly AssetId[];
  label: string;
  selectedCurrency: AssetId;
  inputProps: JSX.IntrinsicElements["input"];
  conversion?: string;
  error?: string | null;
  deploymentName?: string;
  currencySymbolBeforeInput?: string;
  currencySymbolAfterInput?: string;
  displayNameOverride?: { [key: string]: string };
  type: "collateral" | "loan";
};

export function CalculatorInputField(props: CalculatorInputFieldProps) {
  const { label, error, selectedCurrency, type, deploymentName } = props;

  const errorColor = " text-danger placeholder:text-danger";

  return (
    <div className="flex flex-col ">
      <label className="mb-1 flex flex-row gap-1 font-sans text-[1rem] font-semibold text-input-label">
        {label}
        {type === "loan" &&
          selectedCurrency !== "DAI" &&
          deploymentName === "k33" && (
            <p className="hidden font-light italic text-gray-600 sm:block">
              {" (loan nominated and issued in $DAI)"}
            </p>
          )}
      </label>

      <div
        id="input-field-container"
        className="flex h-full w-full flex-row items-center justify-between rounded-[0.25rem] border border-input-border bg-white focus:border-2"
      >
        <TextInputWithConversion {...props} />
        <ChangeCurrencyButton {...props} />
      </div>
      <p className={"mt-1 h-2 text-xs font-semibold" + errorColor}>{error}</p>
    </div>
  );
}

// shows current input as well as conversion value
function TextInputWithConversion(props: {
  currencySymbolBeforeInput?: string;
  currencySymbolAfterInput?: string;
  inputProps: JSX.IntrinsicElements["input"];
  conversion?: string;
}) {
  const {
    inputProps,
    currencySymbolAfterInput,
    currencySymbolBeforeInput,
    conversion,
  } = props;

  const { value } = inputProps;

  return (
    <div
      id="input-conversion-container"
      className={"relative flex w-full flex-row items-center pl-1 text-[1rem]"}
    >
      {value && currencySymbolBeforeInput && (
        <p className="pl-2 font-bold text-input-value">
          {currencySymbolBeforeInput}
        </p>
      )}

      <input
        {...inputProps}
        className={
          "w-min-[10px] h-[35px] w-full border-transparent font-sans text-[1rem] font-bold text-input-value outline-none placeholder:text-placeholder placeholder:text-opacity-50 placeholder:opacity-50 " +
          (currencySymbolBeforeInput && value ? "" : " pl-2")
        }
      />

      {
        // creates an invisible value to overlay over the current input for currency symbol formatting
        value && currencySymbolAfterInput && (
          <div
            className={
              "pointer-events-none absolute flex w-full flex-row items-end gap-1 overflow-hidden whitespace-nowrap"
            }
          >
            <p
              className={
                "w-min-[10px] pl-2 font-sans font-bold text-transparent"
              }
            >
              {value}
            </p>
            <p className={`font-bold text-input-value`}>
              {currencySymbolAfterInput}
            </p>
          </div>
        )
      }

      {conversion && (
        <div className="pointer-events-none absolute flex w-full flex-row items-start gap-2 overflow-hidden whitespace-nowrap">
          <p
            className={
              "w-min-[10px] pl-2 font-sans text-[1rem] font-bold text-transparent"
            }
          >
            {value}
          </p>
          <p
            className={` text-[1rem] font-bold text-placeholder text-opacity-50`}
          >
            {conversion}
          </p>
        </div>
      )}
    </div>
  );
}
