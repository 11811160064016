import { BigFloat } from "shared/build/src/utils/BigFloat";
import { Adapter } from "shared/build/src/services/adapters";
import { SupportedLoanCurrencyId, getLtvValues } from "shared";
import { formatToDisplayStringWithCurrency } from "src/formatting/functions";

export function getMaxLoan(DAICollateral: BigFloat, maxLtv: BigFloat) {
  // TODO: remove magic number
  if (DAICollateral.equals(BigFloat.zero())) return BigFloat.fromNumber(40000);

  return DAICollateral.toDecimals(100).mul(maxLtv);
}

export function getLiquidationValue(args: {
  loanAmount: BigFloat;
  liquidationLtv: BigFloat;
  collateralInLoanCurrency: BigFloat;
}) {
  const { collateralInLoanCurrency, loanAmount, liquidationLtv } = args;

  const liqCollateral = loanAmount.div(liquidationLtv);

  return collateralInLoanCurrency.sub(liqCollateral);
}

export function getLiquidationPrice(args: {
  loanAmount: BigFloat;
  collateralAmount: BigFloat;
  liquidationLtv: BigFloat;
}) {
  const { loanAmount, collateralAmount, liquidationLtv } = args;

  return loanAmount.div(liquidationLtv.mul(collateralAmount));
}

export function getSliderPosition(args: {
  loanAmount: BigFloat;
  minLoan: BigFloat;
  maxLoan: BigFloat;
}) {
  const { loanAmount, maxLoan, minLoan } = args;

  // some magic number math to make the slider bar follow the thumb, requires right ratio offsets. Editing the slider css itself is too cumbersome and makes it unusable
  // big float math creates unexpected offsets
  const min = 4;
  const max = 99;

  const x = loanAmount.formatAndRound() - minLoan.formatAndRound();
  const y = maxLoan.formatAndRound() - minLoan.formatAndRound();
  const z = x / y;

  return Math.max(Math.min(z * 100, max), min);
}

export function getSliderValues(args: {
  loanAmount: BigFloat;
  maxLoan: BigFloat;
  minLoan: BigFloat;
}) {
  const { loanAmount, maxLoan, minLoan } = args;

  const sliderPos = getSliderPosition({
    loanAmount,
    maxLoan,
    minLoan,
  });

  const sliderClassName =
    sliderPos < 0.5 || maxLoan.lessThanOrEqualTo(minLoan)
      ? "bg-transparent"
      : sliderPos > 50
      ? "rounded-lg bg-calculator-slider"
      : "rounded-l-lg bg-calculator-slider";

  return {
    sliderPos,
    sliderClassName,
  };
}

export function getDisplayValues(args: {
  maxLoanInLoanCurrency: BigFloat;
  loanAmountBigFloat: BigFloat;
  collateralBigFloat: BigFloat;
  liqLtv: BigFloat;
  collateralToLoanRate: BigFloat;
  loanCurrency: SupportedLoanCurrencyId;
}) {
  const {
    maxLoanInLoanCurrency,
    loanAmountBigFloat,
    collateralBigFloat,
    collateralToLoanRate,
    liqLtv,
    loanCurrency,
  } = args;

  const loanAmountUntilMaxLtv = maxLoanInLoanCurrency.sub(loanAmountBigFloat);

  const liquidationPrice =
    loanAmountBigFloat.equals(BigFloat.zero()) ||
    collateralBigFloat.equals(BigFloat.zero())
      ? BigFloat.zero()
      : getLiquidationPrice({
          loanAmount: loanAmountBigFloat,
          collateralAmount: collateralBigFloat,
          liquidationLtv: liqLtv,
        });

  const liquidationValue = getLiquidationValue({
    loanAmount: loanAmountBigFloat,
    liquidationLtv: liqLtv,
    collateralInLoanCurrency: collateralBigFloat.mul(collateralToLoanRate),
  });

  const liqLtvTooltipText = `This is the debt/collateral ratio at which your position can be liquidated. Your collateral can drop ${formatToDisplayStringWithCurrency(
    loanCurrency,
    Math.abs(liquidationValue.formatAndRound())
  )} in value before your position is liquidated.`;

  const maxLtvTooltipText = `You can borrow an additional ${formatToDisplayStringWithCurrency(
    loanCurrency,
    loanAmountBigFloat.formatAndRound() >=
      maxLoanInLoanCurrency.formatAndRound()
      ? "0"
      : Math.abs(loanAmountUntilMaxLtv.formatAndRound())
  )} before reaching your maximum
  LTV threshold. This is not your liquidation point, but is the
  maximum loan to value ratio that your debt can reach as a percentage of
  your collateral.`;

  return {
    liquidationPrice,
    liqLtvTooltipText,
    maxLtvTooltipText
  };
}

export function getSpecificAdapter(adapterId: string, adapters: Adapter[]) {
  for (const adapter of adapters) {
    if (adapter.id === adapterId) return adapter;
  }

  throw new Error("Unknown adapterId in getSpecificAdapter: " + adapterId);
}
